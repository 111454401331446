export const TRANSCRIPTS: TranscriptsConfigProps = {
  default: [
    {
      transcript: 'OK Hubbl',
      type: 'WW',
      repeats: 13
    },
    {
      transcript: 'OK Hubbl (at a faster pace)',
      type: 'WW',
      repeats: 2
    },
    {
      transcript: 'Hello Glass',
      type: 'WW',
      repeats: 13
    },
    {
      transcript: 'Hello Glass (at a faster pace)',
      type: 'WW',
      repeats: 2
    }
  ],
  kids: [
    {
      transcript: 'OK Hubbl',
      type: 'WW',
      repeats: 13
    },
    {
      transcript: 'OK Hubbl (at a faster pace)',
      type: 'WW',
      repeats: 2
    },
    {
      transcript: 'Hello Glass',
      type: 'WW',
      repeats: 13
    },
    {
      transcript: 'Hello Glass (at a faster pace)',
      type: 'WW',
      repeats: 2
    }
  ]
}
