export const USER_DETAILS_FORM: UserDetailsFormConfigProps = {
  AGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: '<15', text: 'Under 15' },
    { value: '15-29', text: '15-29' },
    { value: '30-49', text: '30-49' },
    { value: '50-69', text: '50-69' },
    { value: '70+', text: '70+' }
  ],

  GENDER_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
    { value: 'other', text: 'Other' },
    { value: 'preferNotToSay', text: 'Prefer not to say' }
  ],

  ACCENT_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'australian', text: 'Australian' },
    { value: 'american', text: 'American' },
    { value: 'british', text: 'British' },
    { value: 'canadian', text: 'Canadian' },
    { value: 'chinese', text: 'Chinese' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'french', text: 'French' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'indian', text: 'Indian' },
    { value: 'irish', text: 'Irish' },
    { value: 'italian', text: 'Italian' },
    { value: 'newZealand', text: 'New Zealand' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'korean', text: 'Korean' },
    { value: 'russian', text: 'Russian' },
    { value: 'scottish', text: 'Scottish' },
    { value: 'africanSouth', text: 'South African' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'welsh', text: 'Welsh' },
    { value: 'vietnamese', text: 'Vietnamese' },
    { value: 'filipino', text: 'Filipino' },
    { value: 'other', text: 'Other' }
  ],

  REGION_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'newSouthWales', text: 'New South Wales' },
    { value: 'northernTerritory', text: 'Northern Territory' },
    { value: 'victoria', text: 'Victoria' },
    { value: 'queensland', text: 'Queensland' },
    { value: 'westernAustralia', text: 'Western Australia' },
    { value: 'tasmania', text: 'Tasmania' },
    { value: 'southAustralia', text: 'South Australia' }
  ],

  LANGUAGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'english', text: 'English' },
    { value: 'arabic', text: 'Arabic' },
    { value: 'bengali', text: 'Bengali' },
    { value: 'cantonese', text: 'Cantonese' },
    { value: 'croatian', text: 'Croatian' },
    { value: 'czech', text: 'Czech' },
    { value: 'danish', text: 'Danish' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'filipino', text: 'Filipino' },
    { value: 'finnish', text: 'Finnish' },
    { value: 'french', text: 'French' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'italian', text: 'Italian' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'korean', text: 'Korean' },
    { value: 'mandarin', text: 'Mandarin' },
    { value: 'nepali', text: 'Nepali' },
    { value: 'norwegian', text: 'Norwegian' },
    { value: 'polish', text: 'Polish' },
    { value: 'portuguese', text: 'Portuguese' },
    { value: 'punjabi', text: 'Punjabi' },
    { value: 'russian', text: 'Russian' },
    { value: 'serbian', text: 'Serbian' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'swedish', text: 'Swedish' },
    { value: 'turkish', text: 'Turkish' },
    { value: 'vietnamese', text: 'Vietnamese' },
    { value: 'other', text: 'Other' }
  ],

  SECOND_LANGUAGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'english', text: 'English' },
    { value: 'arabic', text: 'Arabic' },
    { value: 'bengali', text: 'Bengali' },
    { value: 'cantonese', text: 'Cantonese' },
    { value: 'croatian', text: 'Croatian' },
    { value: 'czech', text: 'Czech' },
    { value: 'danish', text: 'Danish' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'filipino', text: 'Filipino' },
    { value: 'finnish', text: 'Finnish' },
    { value: 'french', text: 'French' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'italian', text: 'Italian' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'korean', text: 'Korean' },
    { value: 'mandarin', text: 'Mandarin' },
    { value: 'nepali', text: 'Nepali' },
    { value: 'norwegian', text: 'Norwegian' },
    { value: 'polish', text: 'Polish' },
    { value: 'portuguese', text: 'Portuguese' },
    { value: 'punjabi', text: 'Punjabi' },
    { value: 'russian', text: 'Russian' },
    { value: 'serbian', text: 'Serbian' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'swedish', text: 'Swedish' },
    { value: 'turkish', text: 'Turkish' },
    { value: 'vietnamese', text: 'Vietnamese' },
    { value: 'other', text: 'Other' }
  ]
}
